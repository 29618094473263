<template>
  <div class="container">
    <!-- 页面内容可以省略，因为页面将在mounted阶段根据条件跳转 -->
    <!--    <div class="loading-text">Loading...</div>-->
  </div>
</template>

<script>
import axios from "@/js/axios";

export default {

  data() {
    return {
      orderId: "",  //订单号
      createTime: "",
      DDRQ: "",//订单日期
      code: "",
      msg: "",
      invoices: [],
      list: "", //list
      buyName: "",
      price: 0,
      isBLUE: "",
    };
  },
  created() {
    // 在 created 钩子中获取 URL 参数
    this.list = this.$route.query.list || null;
    this.orderId = this.$route.query.orderId || null;
    this.DDRQ = this.$route.query.DDRQ || null;
    if (this.orderId) {
      this.fetchInvoiceInfo();
    } else {
      // 如果没有提供 orderId，跳转到错误页面或处理逻辑
      // this.$router.push('/error'); // 假设有个 error 页面
      alert("请输入订单号！")
    }
  },
  methods: {
    fetchInvoiceInfo: function () {
      const vm = this;
      axios.get(`http://120.46.13.248:8080/api/invoiceHeader/select?id=${this.orderId}`)
          .then(function (response) {
            const results = response.data || {};
            const buyName = results.buyName;
            vm.buyName = buyName;
            console.log("buyName", buyName)
            console.log("results", results)
            let code = response.status;
            let msg = response.statusText;
            console.log("Object.keys(results).length:", Object.keys(results).length)
            if (results && Object.keys(results).length > 0) {
              vm.code = code;
              vm.msg = msg;
              vm.invoices = results;
              vm.checkCreateTime(results.createTime, results.orderDate);
            } else {
              vm.$router.push({
                path: '/doInvoice',
                query: {
                  orderId: vm.orderId,
                  DDRQ: vm.DDRQ
                }
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            vm.code = 0;
            vm.msg = error.message;
            vm.invoices = [];
          });
    },
    checkCreateTime: function (createTime, orderDate) {
      const vm =this;
      // const currentTime = new Date();
      // const creationTime = new Date(createTime);
      // const timeDiff = (currentTime - creationTime) / (1000 * 60 * 60); // 时间差以小时计
      // if (timeDiff > 48) {
      //   // 如果时间间隔超过48小时
      //   this.$router.push('/contactAdmin');
      // } else {
      //   //下面的所有代码
      // }

        // const OverSixtyDays = this.checkOrderDateTime(orderDate)
        // 如果时间间隔在48小时内
        // if (OverSixtyDays) {
        //   //在60天内，走正常逻辑
        // }else {
        //   //超过60天
        //   alert('订单日期超过60天，无法开票');
        // }
        //   // 如果时间间隔在48小时内
        //   this.$router.push({
        //     path: '/success',
        //     query: {
        //       orderId: this.orderId,
        //       DDRQ: this.DDRQ
        //     }
        //   });
      axios.get(`http://120.46.13.248:8080/taxCloud/queryKpInfoByDDH?DDH=${vm.orderId}`)
          .then(function (response) {
            console.log("response",response)
            const result = response.data || {};
            console.log("result", result)
            // 检查接口返回的数据，确保包含 "isBLUE" 和 "price"
            if (result) {
              const isBLUE = result.isBLUE;  // 获取 isBLUE 值
              const price = result.price;    // 获取 price 值
              const orderId = vm.orderId;
              const buyName = vm.buyName;
              if (isBLUE === true) {
                // 如果 isBLUE 为 true，设置价格并跳转到 success 页面
                window.location.href = `/success?orderId=${encodeURIComponent(orderId)}&buyName=${encodeURIComponent(buyName)}&price=${encodeURIComponent(price)}`;
              } else {
                // 如果 isBLUE 为 0，跳转到 wait 页面
                console.log("isBLUE", isBLUE)
                window.location.href = '/wait';
              }
            } else {
              // 如果未能正确的查询到数据，跳转到 wait 页面
              console.log("result", result)
              window.location.href = '/wait';
            }
          })
        }

    },
    checkOrderDateTime: function (orderDate) {
      const currentTime = new Date();
      // 将 orderDate 转换为 Date 对象（假设 orderDate 是 '20240912' 格式）
      const year = parseInt(orderDate.substring(0, 4), 10);
      const month = parseInt(orderDate.substring(4, 6), 10) - 1; // JavaScript 中的月份是从 0 开始的
      const day = parseInt(orderDate.substring(6, 8), 10);
      const orderDateTime = new Date(year, month, day);

      // 计算两个日期之间的差异（以天为单位）
      const timeDiffInMilliseconds = currentTime - orderDateTime;
      const daysDiff = timeDiffInMilliseconds / (1000 * 60 * 60 * 24); // 转换为天数

      if (daysDiff > 60) {
        // 如果时间差超过60天
        return false;
      } else {
        return true;
      }
    },
};
</script>

<style scoped>
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full height for mobile responsiveness */
}

.loading-text {
  font-size: 18px;
  color: #333;
  text-align: center;
}

/* Mobile styling adjustments */
@media (max-width: 600px) {
  .loading-text {
    font-size: 16px; /* Slightly smaller text on smaller screens */
  }
}
</style>
